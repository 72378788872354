import request from "./http"

/**
 * @description 项目下拉框使用
 * @return 项目下拉框
 */
export function getProjectOptions() {
  return request({
    url: `/api/admin/project/getProjectList`,
    method: "post",
  })
}

/**
 * @description 根据当前店铺查询项目列表
 * @return 项目列表
 */
export function getProjectOptionsBySid(storeId) {
  return request({
    url: `/api/admin/project/getListByStid?storeId=${storeId}`,
    method: "get",
  })
}

/**
 * @description 店铺下拉框使用
 * @return 店铺下拉框
 */
export function getStoreOptions() {
  return request({
    url: `/api/admin/store/getStoreList`,
    method: "post",
  })
}

/**
 * @description 项目分类下拉框使用
 * @return 项目下拉框
 */
export function getProjectClassifyOptions() {
  return request({
    url: `/api/admin/project/getProjectClassifyList`,
    method: "post",
  })
}

/**
 * @description 用户下拉框使用
 * @return 用户下拉框
 */
export function getUserOptions() {
  return request({
    url: `/api/admin/user/getAll`,
    method: "post",
  })
}
