import request from "./http"


/**
 * @description 获取项目分类
 *  @param  {String} limit 多少页
 *  @param  {String} page 第几页
 *  @param  {Object}}data 参数
 * @return 返回店铺数据
 */
 export function getClassifyListByPage(limit, page, data) {
    return request({
        url: `/api/admin/classify/list?limit=${limit}&page=${page}`,
        method: "get",
        data
    })
}


/**
 * @description 新增项目分类
 *  @param {Object} data 表单数据
 * @return 新增成功
 */
export function addProjectClassify(data) {
    return request({
        url: `/api/admin/classify/create`,
        method: "post",
        data
    })
}


/**
 * @description 修改项目分类
 *  @param {String} id 项目分类id
 *  @param {Object} data 表单数据
 * @return 修改成功
 */
export function updateProjectClassify(id, data) {
    return request({
        url: `/api/admin/classify/update/${id}`,
        method: "post",
        data
    })
}


/**
 * @description 删除项目分类
 *  @param {String} id 表单数据
 * @return 删除成功
 */
export function delProjectClassify(id) {
    return request({
        url: `/api/admin/classify/delete/${id}`,
        method: "post",
    })
}